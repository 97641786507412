import React from "react"
import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"

import { borderRadii } from "../variables/border/Radii"
import { colours } from "../variables/colour/Colour"
import { breakpoints } from "../variables/layout/Breakpoints"
import { horizontal, vertical } from "../variables/layout/Spacing"
import { Body, typographyStyles } from "./typography/Typography"

type Kind =
  | "opaque"
  | "semi-transparent"
  | "transparent"
  | "transparent-dark"
  | "white"

const backgroundColorForKind: Record<Kind, string> = {
  opaque: colours.white,
  white: colours.white,
  "semi-transparent": colours.white,
  transparent: "transparent",
  "transparent-dark": "transparent",
}

const borderColorForKind: Record<Kind, string> = {
  opaque: colours.offBlack,
  white: "transparent",
  "semi-transparent": "transparent",
  transparent: colours.white,
  "transparent-dark": colours.offBlack,
}

const opacityForNonOpaqueKind: Record<
  Exclude<Kind, "opaque" | "white">,
  number
> = {
  "semi-transparent": 0.5,
  transparent: 0,
  "transparent-dark": 0,
}

const BORDER_WIDTH = "2px"

interface CardStylesProps {
  borderRadius?: keyof typeof borderRadii
  kind?: Kind
}

const cardStyles = ({
  borderRadius = "s",
  kind = "opaque",
}: CardStylesProps = {}): SerializedStyles => css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: ${BORDER_WIDTH} solid ${borderColorForKind[kind]};
  border-radius: ${borderRadii[borderRadius]};
  padding: ${vertical.s} ${horizontal.m};
  list-style-type: none; // For when element="li"
  position: relative;

  ${kind === "opaque" || kind === "white"
    ? `
      background-color: ${backgroundColorForKind[kind]};
    `
    : `
      position: relative;
      z-index: 0;

      ::before {
        background-color: ${backgroundColorForKind[kind]};
        bottom: 0;
        content: "";
        left: 0;
        margin: -${BORDER_WIDTH};
        opacity: ${opacityForNonOpaqueKind[kind]};
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;

        ${
          kind === "semi-transparent"
            ? `
              border: ${BORDER_WIDTH} solid ${backgroundColorForKind[kind]};
              border-radius: ${borderRadii[borderRadius]};
            `
            : ""
        }
      }
    `}

  ${breakpoints.tablet`
    padding: ${vertical.m} ${horizontal.l};
  `}
`

type PermittedElements =
  | "div"
  | "section"
  | "a"
  | "button"
  | "article"
  | "dl"
  | "li"

type PermittedTitleElements = "p" | "h2" | "h3" | "h4" | "h5" | "h6"

const DefaultContainer = styled.div<CardStylesProps>(cardStyles)

/**
 * This type allows the user of the Card component to pass any HTML attributes
 * that are valid for their choice of `element`. For example, if they pass
 * element="a", we also let them pass an `href`; but if they choose
 * element="section", then `href` is not allowed.
 */
type ElementProps = {
  [E in PermittedElements]: { element: E } & JSX.IntrinsicElements[E]
}[PermittedElements]

type Props = ElementProps & CardStylesProps

type TitleElementProps = {
  titleElement: PermittedTitleElements
  children?: React.ReactNode
}

export const Card: React.FC<Props> = ({
  children,
  borderRadius,
  kind,
  element,
  ...props
}) => {
  // Don't recreate the container on every render
  const Container = React.useMemo(
    () => DefaultContainer.withComponent(element),
    [element]
  )
  return (
    <Container kind={kind} borderRadius={borderRadius} {...props}>
      {children}
    </Container>
  )
}

export const CardTitle: React.FC<TitleElementProps> = ({
  titleElement,
  children,
  ...props
}) => {
  const Container = titleElement
  return (
    <Container
      {...props}
      css={css`
        ${typographyStyles.headlineBold}
        margin-top: 0;
      `}
    >
      {children}
    </Container>
  )
}

type ContentCardProps = Props &
  TitleElementProps & {
    title: string
    description: string
    sideContent?: React.ReactNode
  }

export const ContentCard: React.FC<ContentCardProps> = ({
  title,
  titleElement,
  description,
  sideContent,
  children,
  ...props
}) => (
  <Card {...props}>
    <div
      css={css`
        display: flex;
        flex-direction: column-reverse;

        ${breakpoints.tablet`
        flex-direction: row;
      `}
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        <CardTitle titleElement={titleElement}>{title}</CardTitle>
        <Body>{description}</Body>
        {children}
      </div>
      {sideContent}
    </div>
  </Card>
)
